.map-container {
  position: relative;
  width: 100%;
  height: auto; 
  overflow: hidden;
  margin-bottom: 20px; 
}

canvas {
  width: 100%;
  height: auto;
  max-height: 800px; 
  transition: transform 0.1s;
  touch-action: none;
}

.canvas-buttons, .canvas-pin-buttons {
  position: absolute;
  right: 10px;
  display: flex;
  flex-direction: column;
}

.canvas-buttons {
  top: 10px;
}

.canvas-pin-buttons {
  bottom: 10px;
}

.canvas-button {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.canvas-button:hover {
  background: rgba(255, 255, 255, 1);
}

.search-container {
  max-height: 85vh;
  overflow-y: auto;
}

.icon-links {
  display: flex;
  gap: 10px;
}

.icon-link {
  color: inherit;
  text-decoration: none;
  font-size: 1.5rem;
}

@media (min-aspect-ratio: 4/3) {
  .row {
    flex-direction: row;
  }

  .search-container {
    width: 35%;
    margin-left: 10px;
    margin-top: 0;
  }

  .map-container {
    width: 70%;
    height: 700px;
  }
}

@media (max-width: 768px) {
  .form-inline .form-group,
  .form-inline .btn {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-group {
    margin-right: 10px;
  }

  .form-inline {
    text-align: center;
  }
}

.middle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
